export default {
  // Colors
  colors: {
    primary: '#015770',
    secondary: '#ff8868',
    accent: '#ff8868',
    error: '#fc5954',
    success: '#fc5954',
    info: '#fc5954'
  },

  // Fonts
  font: {
    h1: {
      size: '',
      lineHeight: ''
    },
    h2: {
      size: '',
      lineHeight: ''
    },
    h3: {
      size: '',
      lineHeight: ''
    },
    h4: {
      size: '',
      lineHeight: ''
    },
    h5: {
      size: '',
      lineHeight: ''
    },
    h6: {
      size: '',
      lineHeight: ''
    },
    p: {
      size: '',
      lineHeight: ''
    }
  },

  // GRID
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: -2, // rem
    outerMargin: 0, // rem
    mediaQuery: 'only screen',
    dimensions: ['xs', 'sm', 'md', 'lg', 'xl'],
    container: {
      sm: 46,
      md: 61,
      lg: 76,
      xl: 86
    },
    breakpoints: {
      xs: 0,
      sm: 48,
      md: 64,
      lg: 75,
      xl: 85
    }
  }
}
