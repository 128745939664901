import styled from 'styled-components'

export const Hover = styled.div`
  position: relative;
  margin: 0;
  font-size: unset;

  &:hover .hover__no-hover {
    opacity: 0;
  }

  &:hover .hover__hover {
    opacity: 1;
  }

  .hover__hover {
    position: absolute;
    top: 0;
    opacity: 0;
  }

  .hover__no-hover {
    opacity: 1;
  }
`
