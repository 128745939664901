import React from 'react'
import { ThemeProvider, injectGlobal } from 'styled-components'

import SEO from '../SEO'
import Main from '../Main'

import { theme } from '../../utils'

import { GlobalStyle } from './styles'

injectGlobal`
  html {
    background-color: #000;
    color: #fff;
  }
  
  html, body, #___gatsby, #___gatsby>div {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  
  #___gatsby { 
    overflow-x: hidden;
    position: relative;
  }

  html * {
    font-family: 'acumin-pro', sans-serif;
    font-style: normal;
  }
`

export default class Layout extends React.Component {
  componentDidMount () {
    document.documentElement.setScaledFont = function () {
      var s = this.offsetWidth
      var fs = (s - 2000) / 5 + 148
      this.style.fontSize = fs + 'px'
      return this
    }

    document.documentElement.setScaledFont()
    window.onresize = function () {
      document.documentElement.setScaledFont()
    }
  }

  render () {
    const { pageTitle, pageDescription, location, children } = this.props
    return (
      <React.Fragment>
        <SEO
          pageTitle={pageTitle}
          pageDescription={pageDescription}
          pathname={location && location.pathname}
        />
        <ThemeProvider theme={theme}>
          <GlobalStyle>
            <Main>{children}</Main>
          </GlobalStyle>
        </ThemeProvider>
      </React.Fragment>
    )
  }
}
