import Img from 'gatsby-image'

import styled from 'styled-components'
import media from '../../utils/media'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  font-weight: normal;
  font-style: normal;
  font-stretch: normal;

  padding: 125px;
  ${media.lessThan('sm')`padding: 50px 35px;`};
  &.pt {
    padding-top: 250px;
    ${media.lessThan('sm')`padding-top: 100px;`};
  }
  &.pb {
    padding-bottom: 250px;
    ${media.lessThan('sm')`padding-top: 100px;`};
  }

  br {
    line-height: 50px;
    ${media.lessThan('sm')`line-height: 10px;`};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a {
    margin: 0;
    padding: 0;
    ::selection {
      color: #ff0033;
      background: rgba(0, 0, 0, 0);
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: 20px;
    font-family: 'Tungsten A', 'Tungsten B';
    font-size: 52px;
    ${media.lessThan('sm')`font-size: 31px;`};
    ${media.greaterThan('2000px')`font-size: .35em;`};
  }

  h1 {
    font-family: 'Tungsten Narrow A', 'Tungsten Narrow B';
    font-size: 148px;
    ${media.lessThan('sm')`font-size: 56px;`};
    ${media.greaterThan('2000px')`font-size: 1em;`};
    line-height: 120px;
    ${media.lessThan('sm')`line-height: 47px;`};
    ${media.greaterThan('2000px')`line-height: .813em;`};
  }

  h1 > a {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    text-decoration: none;
  }

  &#contact {
    padding-right: 0;
    padding-top: 150px;
    padding-bottom: 250px;
    ${media.lessThan('sm')`
      padding-top: 100px;
      padding-bottom: 100px;
    `};
  }

  p,
  .links a {
    font-size: 27px;
    ${media.lessThan('sm')`font-size: 18px;`};
    ${media.greaterThan('2000px')`font-size: .2em;`};
    line-height: 34px;
    ${media.lessThan('sm')`line-height: 23px;`};
    ${media.greaterThan('2000px')`line-height: 1.286em;`};
  }

  &.cards {
    padding-top: 5px;
    padding-bottom: 95px;
    ${media.lessThan('sm')`
      padding-top: 30px;
      padding-bottom: 85px;
    `};
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    & > div {
      padding-bottom: 55px;
      width: calc(50% - 40px / 2);
      ${media.greaterThan('2000px')`width: calc(50% - 120px / 2);`};
      ${media.lessThan('sm')`
        padding-bottom: 15px;
        width: 100%;
      `};
    }
  }

  .links {
    padding-top: 150px;
    ${media.lessThan('sm')`
      padding-top: 50px;
    `};
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    a {
      margin-right: 30px;
      ${media.greaterThan('2000px')`margin-right: 50px;`};
      :last-child {
        margin-right: 0;
      }
    }
  }
`

export const Images = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  margin: 10px 0 137px 0;
  ${media.lessThan('sm')`margin: 47px 0 43px 0;`};
  img {
    margin: 0;
  }
`
export const ImgGlobe = styled.img`
  z-index: 2;
  position: absolute;
  width: 400px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-90%, -10%);
  -ms-transform: translate(-90%, -10%);
  transform: translate(-90%, -10%);

  ${media.lessThan('sm')`
    width: 240px;
    -webkit-transform: translate(-75%, -50%);
    -ms-transform: translate(-75%, -50%);
    transform: translate(-75%, -50%);
  `};
`
export const ImgPortrait = styled(Img)`
  z-index: 1;
  position: absolute;
  width: 400px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-12%, -5%);
  -ms-transform: translate(-12%, -5%);
  transform: translate(-12%, -5%);

  ${media.lessThan('sm')`
    width: 140px;
    -webkit-transform: translate(0%, -65%);
    -ms-transform: translate(0%, -65%);
    transform: translate(0%, -65%);
  `};
`
