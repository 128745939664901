import React from 'react'

import { Hover } from './styles'

export default ({ onHover, children }) => (
  <Hover>
    <div className='hover__no-hover'>{children}</div>
    <div className='hover__hover'>{onHover}</div>
  </Hover>
)
