import styled from 'styled-components'

import { fadeInUp } from '../../utils/animations'

export const GlobalStyle = styled.div`
  background-color: #000;
  color: #fff;

  a {
    color: #fff;
    &:hover {
      color: #ff0033;
    }
  }

  .fadeInUp {
    -webkit-animation-name: ${fadeInUp};
    animation-name: ${fadeInUp};
  }
`
