import { keyframes } from 'styled-components'

export const returnAnim = (animation, prop) => (prop ? animation : 'none')

export const show = keyframes`
  from {
    opacity: 0;
    pointer-events: none;
  }
  to {
    opacity: 1;
  }
`

export const breath = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
 `

export const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 200px, 0);
    transform: translate3d(0, 200px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`
